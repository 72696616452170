// draft-ui
// https://github.com/IHIutch/draft-ui
import { type VariantProps } from 'cva'
import * as ReactAria from 'react-aria-components'

import { cva, cx } from '../lib/cva.config'

export const iconButtonVariants = cva({
  base: [
    'inline-flex items-center justify-center rounded-md font-semibold outline-none transition-colors',
    // Focus
    'focus:ring-2 focus:ring-neutral-400 focus:ring-offset-2 dark:focus:ring-offset-neutral-900',
    // Disabled
    'disabled:pointer-events-none disabled:opacity-40',
  ],
  variants: {
    variant: {
      solid: [
        // Base
        'bg-neutral-900 text-white dark:bg-neutral-50 dark:text-neutral-900',
        // Hover
        'hover:bg-neutral-700 dark:hover:bg-neutral-200',
        // Focus
        'focus:bg-neutral-700 dark:focus:bg-neutral-200',
        // Open
        'open:bg-neutral-100 dark:open:bg-neutral-800',
      ],
      destructive: [
        // Base
        'bg-red-600 text-white',
        // Hover
        'hover:bg-red-600 dark:hover:bg-red-600',
        // Focus
        'focus:bg-red-600 dark:focus:bg-red-600',
        // Open
        '',
      ],
      outline: [
        // Base
        'border border-neutral-200 bg-transparent dark:border-neutral-700 dark:text-neutral-100',
        // Hover
        'hover:bg-neutral-100 dark:hover:bg-neutral-700',
        // Focus
        'focus:bg-neutral-100 dark:focus:bg-neutral-700',
        // Open
        '',
      ],
      subtle: [
        // Base
        'bg-neutral-100 text-neutral-900 dark:bg-neutral-700 dark:text-neutral-100',
        // Hover
        'hover:bg-neutral-200 dark:hover:bg-neutral-600',
        // Focus
        'focus:bg-neutral-200 dark:focus:bg-neutral-600',
        // Open
        '',
      ],
      ghost: [
        // Base
        'bg-transparent dark:text-neutral-100',
        // Hover
        'hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-100',
        // Focus
        'focus:bg-neutral-100 dark:focus:bg-neutral-800 dark:focus:text-neutral-100',
        // Open
        'open:bg-transparent dark:open:bg-transparent',
      ],
      link: [
        // Base
        'bg-transparent text-neutral-900 underline-offset-4 dark:bg-transparent dark:text-neutral-100',
        // Hover
        'hover:bg-transparent hover:underline dark:hover:bg-transparent',
        // Focus
        'focus:bg-transparent focus:underline dark:focus:bg-transparent',
        // Open
        '',
      ],
    },
    size: {
      lg: 'h-12 w-12 rounded-lg text-3xl',
      md: 'h-10 w-10 rounded-md text-2xl',
      sm: 'h-8 w-8 rounded px-1 text-xl',
      xs: 'h-6 w-6 rounded px-1 text-lg',
    },
  },
  defaultVariants: {
    variant: 'solid',
    size: 'md',
  },
})

export interface IconButtonProps
  extends ReactAria.ButtonProps,
    VariantProps<typeof iconButtonVariants> {
  className?: string
  'aria-label': string
}

export const IconButton = ({
  className,
  variant,
  size,
  ...props
}: IconButtonProps) => {
  return (
    <ReactAria.Button
      className={cx(
        iconButtonVariants({
          variant,
          size,
          className,
        }),
      )}
      {...props}
    />
  )
}
